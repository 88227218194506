// @ts-ignore
import styles from '../styles.module.css';

export function CameraExistError() {
  return <div className={styles.camera__error_wrap}>
      <div className={styles.camera__error}>
        <div className={styles.camera__error_icon}>
          <img src={`${process.env.PUBLIC_URL}/assets/error.svg`} alt="Camera Error"/>
        </div>
        <p className={styles.camera__error_text}>
          Camera&nbsp;is necessary. Please connect your&nbsp;camera and&nbsp;try again.
        </p>
      </div>
    </div>  
}
