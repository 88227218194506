import '@mediapipe/camera_utils'
import { Camera } from '@mediapipe/camera_utils';
import { IEvent, produceObserver } from './utils/observer'
import { videoResolution } from './constants'

export interface ICameraFrame {
  facingMode: CameraDirection
  videoElement: HTMLVideoElement
}

export enum CameraDirection {
  User = 'user',
  Environment = 'environment',
}

export interface ICamera {
  direction: CameraDirection
  onCameraFrameEvent: IEvent<ICameraFrame>
  stopCamera: () => Promise<void>
}

export function startCamera(video: HTMLVideoElement, cameraDirection: CameraDirection): ICamera {    
  let camera = new Camera(video, {
    ...videoResolution,
    onFrame: callback,
    facingMode: cameraDirection,
  })

  async function callback() {
    return onCameraFrameEvent.notify({ facingMode: cameraDirection, videoElement: video });
  }

  //workaround to work in iOS Safari
  video.setAttribute('playsinline', '')
  const onCameraFrameEvent = produceObserver<ICameraFrame>()
 
  camera.start()

  return {
    stopCamera: () => camera.stop(),
    direction: cameraDirection,
    onCameraFrameEvent,
  }
}
