import { Object3D, Vector3 } from 'three'
import { IEvent } from '../utils/observer'
import { IHandData } from './virtual-hand'

export function setupLinesHandAR(
  root: Object3D,
  updateCallback: (points: Vector3[], width: number) => void,
  handUpdatedEvent: IEvent<IHandData>,
  handVisibilityChanged: IEvent<boolean>,
) {
  function update(data: IHandData) {
    updateCallback(data.transformedPoints, data.scale)
  }

  function visibilityChange(visible: boolean) {
    root.visible = visible
  }

  function start() {
    handUpdatedEvent.subscribe(update)
    handVisibilityChanged.subscribe(visibilityChange)
  }

  function stop() {
    handUpdatedEvent.unsubscribe(update)
    handVisibilityChanged.unsubscribe(visibilityChange)
  }

  return { start, stop }
}
