import type { Hands as HandsType, Results, Options } from '@mediapipe/hands'
import { Hands } from '@mediapipe/hands'
import '@mediapipe/drawing_utils'
import '@mediapipe/camera_utils'
import { ITrackingGraph } from './types'
import { createTrackingGraph } from './tracking-essential'

interface ExtOptions extends Options{
  useCpuInference?: boolean
}

export function createHandsTrackingGraph(): ITrackingGraph<Results> {

  const options:ExtOptions = {
    maxNumHands: 1,
    minDetectionConfidence: 0.4,
    minTrackingConfidence: 0.4,
    modelComplexity: 1,
    useCpuInference: false
  }

  const hands: HandsType = new Hands({    
    locateFile: (file: string) => `https://testingcf.jsdelivr.net/npm/@mediapipe/hands/${file}`,
  })

  hands.setOptions(options)

  return createTrackingGraph<Results>(hands)
}
