import { Object3D, Vector3 } from 'three'
import { IFaceData } from './virtual-face'
import { IEvent } from '../utils/observer'
import { IObservableProperty } from '../utils/observableProperty'
import { createFaceTransformFromFaceData } from '../faceTransform'
import { FilterPosition, FilterRotation, FilterScalar } from '../filters'


export function setupEarringAR(earring: Object3D, isLeft: boolean, updateEvent: IEvent<IFaceData>, isVisible: IObservableProperty<boolean>) {
  const earringLocalPosition = new Vector3(0.5, -0.125, 0.3)
  const earringLocalScale = 0.1

  const scaleFilter = new FilterScalar(0.2)
  const positionFilter = new FilterPosition(0.6)
  const rotationFilter = new FilterRotation(0.3)

  function onVisibilityChange(visible: boolean) {
    earring.visible = visible
  }

  function onFaceUpdate(data: IFaceData) {
    const transform = createFaceTransformFromFaceData(data)

    const filteredPosition = positionFilter.Filter(transform.position)
    const filteredRotation = rotationFilter.Filter(transform.rotation)
    const filteredScale = scaleFilter.Filter(transform.scale.x)

    earring.position.copy(filteredPosition)
    earring.quaternion.copy(filteredRotation)
    earring.scale.setScalar(filteredScale * earringLocalScale)
    earring.scale.x *= (isLeft ? 1 : -1)

    earring.translateX(earringLocalPosition.x * filteredScale * (isLeft ? -1 : 1))
    earring.translateY(earringLocalPosition.y * filteredScale)
    earring.translateZ(earringLocalPosition.z * filteredScale)

    earring.rotateX(-0.3)

    //earring.rotateY(isLeft? 0.2:-0.2)
  }

  function start() {
    onVisibilityChange(isVisible.value)

    isVisible.subscribe(onVisibilityChange)
    updateEvent.subscribe(onFaceUpdate)
  }

  function stop() {
    isVisible.unsubscribe(onVisibilityChange)
    updateEvent.unsubscribe(onFaceUpdate)
  }

  return { start, stop }
}
