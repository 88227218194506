export function attributeIsSetted(htmlElem: HTMLElement, attrName: string) {
  const attr = htmlElem.getAttribute(attrName);
  return attr === 'true' || (attr != null && attr !== 'false');
}

export function observeAttribute(htmlElem: HTMLElement, attrName: string, callback: Function) {
  var attributeObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.type === "attributes") {
        // console.log("attributes changed");
        callback();
      }
    });
  });

  attributeObserver.observe(htmlElem, {
    attributes: true
  });
}
