import '@mediapipe/camera_utils'
import '@mediapipe/face_mesh'
import { FaceMesh, Results } from '@mediapipe/face_mesh'
import { ITrackingGraph } from './types'
import { createTrackingGraph } from './tracking-essential'

const graphOptions = {
  selfieMode: true,
  enableFaceGeometry: false,
  maxNumFaces: 1,
  refineLandmarks: false,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
}

export function createFaceMeshTrackingGraph(): ITrackingGraph<Results> {  
  const faceMesh = new FaceMesh({
    locateFile: (file: string) => {
      return `https://testingcf.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
    },
  })

  faceMesh.setOptions(graphOptions)

  return createTrackingGraph<Results>(faceMesh)
}



