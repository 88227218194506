import { IRenderer3D } from '../object-render'
import { ITryOnSession } from './types'
import { createMultiProgress } from '../create-multi-progress'
import { getJewelryPath } from '../getJewelryPath'
import { Object3D } from 'three'
import { importJewelry } from '../import-jewelry'
import { createCube } from '../utils/three-primitives'
import { createProperty } from '../utils/observableProperty'
import { drawImage, drawPoseResultsOnCanvas } from '../tracking/drawing'
import { ICameraFrame } from '../camera'
import { createPoseTrackingGraph } from '../tracking/pose-tracking-graph'
import { createVirtualPose} from '../ar/virtual-pose'
import { createNecklaceAR } from '../ar/necklace-ar'

export async function startNecklaceTryOn(
  jewelryId: string,
  arRenderer: IRenderer3D,
  landmarksCanvas: HTMLCanvasElement,
  progressCallback: (progress: number) => void): Promise<ITryOnSession> {
  const currentProgress = createMultiProgress([5,5])
  currentProgress.progressChangedEvent.subscribe(progressCallback)

  const graph = createPoseTrackingGraph()

  const jewelryPath = getJewelryPath(jewelryId)

  const modelRoot = new Object3D()

  if (jewelryPath) {
    importJewelry(jewelryPath, false, onJewelryImportProgress).then(model => {
      modelRoot.add(model)
    })
  } else modelRoot.add(createCube(1, 0xffffff))

  function onJewelryImportProgress(event: ProgressEvent) {
    const progress = event.loaded / event.total
    currentProgress.update(0, progress)
  }

  graph.initialized.onNextValue(() => {
    currentProgress.update(1, 1)
  })

  const videoFlipped = createProperty(false)

  arRenderer.scene.add(modelRoot)

  const pose = createVirtualPose(graph, arRenderer.camera, videoFlipped)
  const necklaceAR = createNecklaceAR(modelRoot, pose.updatedEvent, pose.visibilityChangedEvent)

  graph.resultsEvent.subscribe(results => {
    drawImage(landmarksCanvas, results, videoFlipped.value)
    if (landmarksCanvas.dataset.showLandmarks == 'true') {
      drawPoseResultsOnCanvas(landmarksCanvas, results)
    }
    arRenderer.render()
  })

  function onCameraFrame(frame: ICameraFrame) {
    videoFlipped.value = frame.facingMode == 'user'
    graph.sendVideo(frame.videoElement)
  }

  function stop() {
    graph.stop()
    necklaceAR.stop()
  }

  return {
    initialized: graph.initialized,
    sendVideoCallback: onCameraFrame,
    stop,
  }
}
