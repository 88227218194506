import { createVirtualHand } from '../ar/virtual-hand'
import { setupRingAR } from '../ar/ring-ar'
import { createOcclusionHand } from '../lines-hand'
import { setupLinesHandAR } from '../ar/hand-ar'
import { drawHandsResultsOnCanvas, drawImage } from '../tracking/drawing'
import { createHandsTrackingGraph } from '../tracking/hand-tracking-graph'
import { Object3D } from 'three'
import { getJewelryPath } from '../getJewelryPath'
import { importJewelry } from '../import-jewelry'
import { createCube } from '../utils/three-primitives'
import { IRenderer3D } from '../object-render'
import { createMultiProgress } from '../create-multi-progress'
import { createFingerBorderEstimation } from '../fingerBordersEstimation'
import { createProperty } from '../utils/observableProperty'
import { ICameraFrame } from '../camera'
import { ITryOnSession } from './types'
import { useEffect } from 'react'

export async function startRingTryOn(
  jewelryId: string,
  arRenderer: IRenderer3D,
  landmarksCanvas: HTMLCanvasElement,
  progressCallback: (progress: number) => void): Promise<ITryOnSession> {
  const currentProgress = createMultiProgress([5,5])
  currentProgress.progressChangedEvent.subscribe(progressCallback)

  
  const graph = createHandsTrackingGraph()
  graph.initialized.onNextValue(() => {
    currentProgress.update(1, 1)
  })
  

  const jewelryPath = getJewelryPath(jewelryId)

  const modelRoot = new Object3D()

  if (jewelryPath) {
    importJewelry(jewelryPath, true, onJewelryImportProgress).then(model => {
      modelRoot.add(model)
    })
  } else modelRoot.add(createCube(1, 0xffffff))

  function onJewelryImportProgress(event: ProgressEvent) {
    const progress = event.loaded / event.total
    currentProgress.update(0, progress)
  }

  

  const videoFlipped = createProperty(false)

  const { updatedEvent, visibilityChangedEvent } = createVirtualHand(graph, arRenderer.camera, videoFlipped)

  const fingerBorderEstimation = createFingerBorderEstimation(updatedEvent)

  const ringAR = await setupRingAR(modelRoot, updatedEvent, visibilityChangedEvent, fingerBorderEstimation.estimation, fingerBorderEstimation.estimationDrift)

  const linesHand = createOcclusionHand()
  const handAR = setupLinesHandAR(linesHand.root, linesHand.update, updatedEvent, visibilityChangedEvent)

  arRenderer.scene.add(linesHand.root)
  arRenderer.scene.add(modelRoot)

  ringAR.start()
  handAR.start()
  fingerBorderEstimation.start()

  graph.resultsEvent.subscribe(results => {    
    drawImage(landmarksCanvas, results, videoFlipped.value)
    if (landmarksCanvas.dataset.showLandmarks == 'true') {
      drawHandsResultsOnCanvas(landmarksCanvas, results)
    }
    arRenderer.render()
  })

  function onCameraFrame(frame: ICameraFrame) {    
    videoFlipped.value = frame.facingMode == 'user'
    graph.sendVideo(frame.videoElement)
  }

  function stop() {
    graph.stop()
    ringAR.stop()
    handAR.stop()
    fingerBorderEstimation.stop()
  }

  return {
    initialized: graph.initialized,
    sendVideoCallback: onCameraFrame,
    stop,
  }
}
