import { useRef, useState } from 'react';
import styles from '../styles.module.css';

interface ScreenShotProps {
  canvas1 : HTMLCanvasElement | null,
  canvas2 : HTMLCanvasElement | null,
  width : number,
  height: number,
  setIsScreenshotCanvasActive : (value: boolean) => void
}

export function ScreenShotButton(props : ScreenShotProps){  

  const screenshotRef = useRef<HTMLCanvasElement>(null)
  const [currentUrl] = useState(window.location.href)
  const [isGetScreenshot, setGetScreenshot] = useState(false)
  const [isDownloadBtn, setDownloadBtn] = useState(false)
  const [isShareBtn, setShareBtn] = useState(false)

  //console.log(currentUrl)

  async function getScreenshot() {
    props.setIsScreenshotCanvasActive(true)
    setGetScreenshot(true)
    let ctxScreenshot = screenshotRef.current!.getContext('2d')

    if (!ctxScreenshot) {
      console.error('Screenshot context is required!')
      return
    }

    if (!props.canvas1 || !props.canvas2) {
      console.error('Screenshot context is required!')
      return
    }

    ctxScreenshot.drawImage(props.canvas1, 0, 0)
    ctxScreenshot.drawImage(props.canvas2, 0, 0)

    // @ts-ignore
    if (navigator.share) {
      setShareBtn(true)
    } else {
      setDownloadBtn(true)
    }
    
  }

  function newTryon() {
    let ctxScreenshot = screenshotRef.current!.getContext('2d')

    ctxScreenshot!.clearRect(0, 0, screenshotRef.current!.width, screenshotRef.current!.height);

    setGetScreenshot(false)
    setShareBtn(false)
    setDownloadBtn(false)
    props.setIsScreenshotCanvasActive(false)
  }

  function downloadTryonImage() {
    let ctxScreenshot = screenshotRef.current!.getContext('2d')
    let dataUrl : string = screenshotRef.current!.toDataURL('image/png')
    saveAsFile(dataUrl, 'screenshotRef.png').then(() => {
      ctxScreenshot!.clearRect(0, 0, screenshotRef.current!.width, screenshotRef.current!.height);
    })
  }

  async function shareTryonImage() {
    let ctxScreenshot = screenshotRef.current!.getContext('2d')
    let blob : Blob | null = await new Promise(resolve => screenshotRef.current!.toBlob(resolve, 'image/png'))
    if (!blob) {
      console.error('Blob creation failed!')
      return
    }
    let blobFile = new File([blob], "screenshot.png", {type: 'image/png'});
    navigator.share({
      title: currentUrl,
      files: [blobFile],
    }).then(() => {
      ctxScreenshot!.clearRect(0, 0, screenshotRef.current!.width, screenshotRef.current!.height);
      setShareBtn(false)
    })
  }

  async function saveAsFile(base64: string, fileName: string) {
    let link = document.createElement("a");

    document.body.appendChild(link);

    link.setAttribute("href", base64);
    link.setAttribute("download", fileName);
    link.click();
    setDownloadBtn(false)
    props.setIsScreenshotCanvasActive(false)
  }

  return <div className={styles.screenshot__ui}>
          <div className={isDownloadBtn || isShareBtn ? styles.canvas__overlay : undefined}></div>
          <div className={isDownloadBtn || isShareBtn ? styles.screenshot__canvas_wrap_active : styles.screenshot__canvas_wrap}>
              <div className={isGetScreenshot ? styles.screenshot__canvas_close_active : styles.screenshot__canvas_close_hide}/>              
              <canvas
                width={props.width}
                height={props.height}
                ref={screenshotRef}
                className={styles.screenshot__canvas}
                id='screenshot'
              ></canvas>
          </div>
          <button className={isDownloadBtn || isShareBtn ? styles.get_screenshot_btn_hide : styles.get_screenshot_btn} onClick={getScreenshot}>
            <img src={`${process.env.PUBLIC_URL}/assets/shoot_photo_btn.svg`} alt="Take Photograph" />
          </button>

          <div className={styles.menu__btn_wrap}>
            <button className={isDownloadBtn ? styles.download_btn_show : styles.download_btn} id="download_btn" onClick={downloadTryonImage}>
              <img src={`${process.env.PUBLIC_URL}/assets/download_btn.svg`} alt="Download" />
            </button>
            <button className={isShareBtn ? styles.share_btn_show : styles.share_btn} id="share_btn" onClick={shareTryonImage}>
              <img src={`${process.env.PUBLIC_URL}/assets/share_btn.svg`} alt="Share"/>
            </button>
            <button className={isDownloadBtn || isShareBtn ? styles.tryon_btn_show : styles.tryon_btn} id="newtryon_btn" onClick={newTryon}>
              <img width={30} height={20}  src={`${process.env.PUBLIC_URL}/assets/logo_btn.png`} alt="new Tryon"/>
              <span>New try on</span>
            </button>
          </div>
  </div>
}
