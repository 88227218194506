import { Scene, OrthographicCamera, WebGLRenderer, DirectionalLight } from 'three'
import { setupRenderer } from './renderer'
import { getEnvironment } from './environment'
import { currentCameraZoom } from './zoom'

export interface IRenderer3D {
  scene: Scene
  camera: OrthographicCamera
  canvas: HTMLCanvasElement
  render: Function,
  dispose: Function
}

export function initRenderer({
                               height,
                               width,
                               canvas,
                               progressCallback,
                             }: {
  height: number
  width: number
  canvas: HTMLCanvasElement
  progressCallback: (progress: number) => void
}): IRenderer3D {
  const scene = new Scene()
  const camera = new OrthographicCamera(
    width / -50,
    width / 50,
    height / 50,
    height / -50,
    -100,
    1000,
  )
  const renderer = setupRenderer(canvas, width, height)

  camera.scale.setScalar(currentCameraZoom.value)
  currentCameraZoom.subscribe(scale => camera.scale.setScalar(scale))

  camera.position.z = 100

  getEnvironment(renderer, progressCallback).then(environment => {
    scene.environment = environment.map
  })

  function render() {
    renderer.clear(true, true, true)
    renderer.render(scene, camera)
  }

  function dispose() {
    renderer.dispose()
  }


  return { scene, camera, canvas, render, dispose }
}
