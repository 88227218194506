import { ITrackingGraph } from '../tracking/types'
import { NormalizedLandmark, Results } from '@mediapipe/pose'
import { OrthographicCamera, Vector3 } from 'three'
import { IObservableProperty } from '../utils/observableProperty'
import { produceObserver } from '../utils/observer'
import { transformLandmarkToWorldPoint, transformPointToWorldPoint } from '../transformation'

export interface IPoseData {
  rawData: Results,

  get transformedPoints(): Vector3[]

  get worldPoints(): Vector3[]
}

export function createVirtualPose(
  tracking: ITrackingGraph<Results>,
  camera: OrthographicCamera,
  videoFlipped: IObservableProperty<boolean>,
) {
  let isVisible = false

  tracking.resultsEvent.subscribe(onResults)

  const visibilityChangedEvent = produceObserver<boolean>()
  const updatedEvent = produceObserver<IPoseData>()

  function transformLandmark(landmark: NormalizedLandmark) {
    if (videoFlipped.value) {
      return transformPointToWorldPoint(camera, 1 - landmark.x, landmark.y, landmark.z)
    }
    return transformLandmarkToWorldPoint(camera, landmark)
  }

  function onResults(results: Results) {
    const normalizedLandmarks = results?.poseLandmarks
    const worldLandmarks = results?.poseWorldLandmarks

    if (!normalizedLandmarks || normalizedLandmarks.length === 0) {
      if (isVisible) {
        isVisible = false
        visibilityChangedEvent.notify(false)
      }
      return
    } else {
      if (!isVisible) {
        isVisible = true
        visibilityChangedEvent.notify(true)
      }
    }

    const transformedPoints = normalizedLandmarks.map(landmark => transformLandmark(landmark))
    const worldPoints = worldLandmarks.map((point) => new Vector3((videoFlipped.value ? -1 : 1) * point.x, -point.y, -point.z))

    updatedEvent.notify({
      rawData: results,
      transformedPoints,
      worldPoints,
    })
  }

  return { updatedEvent, visibilityChangedEvent }
}
