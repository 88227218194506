import { produceObserver } from './utils/observer'

export function createMultiProgress(weights:number[]) {

  const progresses = weights.map((e) => {return {value: 0, weight:e}})
  
  const progressChangedEvent = produceObserver<number>()

  return {    
    progressChangedEvent: progressChangedEvent,
    update(index: number, value: number) {
      if(progresses[index].value > value)
        return
      progresses[index].value = value
      const totalWeight = progresses.reduce((a,p) => a + p.weight, 0)
      const calculatedProgress = progresses.reduce((a, b) => a + b.value * b.weight, 0) / totalWeight
      progressChangedEvent.notify(calculatedProgress)
    },
    setAutoIncrement(index: number, amount: number = 0.01, intervalMs: number = 1000){
      const intervalId = setInterval( () =>{
        const curVal = progresses[index].value
        if(curVal < 0.95)
        {
          this.update(index, curVal + amount)
        } else
          clearInterval(intervalId)
      },intervalMs)
    }
  }
}