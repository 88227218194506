import { Color, Vector3 } from 'three'
import { rgb2lab } from './convert'

export function getMaxNeighbourDistance(points: Vector3[]) {
  let maxNeighbourDistance = 0
  for (let i = 0; i < points.length - 1; i++) {
    for (let j = i; j < points.length; j++) {
      const distance = points[i].distanceTo(points[j])
      if (distance > maxNeighbourDistance) maxNeighbourDistance = distance
    }
  }
  return maxNeighbourDistance
}

export function fillColumnColorDerivatives(colors: Color[], width: number, height: number, destination: number[]) {
  for (let x = 1; x < width; x++) {
    let meanDerivative = 0
    for (let y = 0; y < height; y++) {
      const p1 = colors[x - 1 + y * width]
      const lab1 = rgb2lab(p1.r, p1.g, p1.b)
      const p2 = colors[x + y * width]
      const lab2 = rgb2lab(p2.r, p2.g, p2.b)
      const dist = (lab2.clone().sub(lab1)).length()
      meanDerivative += dist
    }

    meanDerivative /= height
    destination[x] = meanDerivative * meanDerivative
  }

  destination[0] = destination[width - 1] = 0
}

export function filterMean(source: number[], destination: number[], filterWidth: number): number[] {
  for (let x = filterWidth; x < source.length - filterWidth; x++) {
    let mean = 0
    for (let m = -filterWidth; m <= filterWidth; m++) {
      mean += source[x + m]
    }

    mean /= filterWidth * 2 + 1
    destination[x] = mean
  }

  return destination
}

export function findMaximum(array: number[], indexStart: number, indexEnd: number) {
  if (indexStart > indexEnd) {
    [indexStart, indexEnd] = [indexEnd, indexStart]
  }

  let currentMax = array[indexStart]
  for (let k = indexStart + 1; k < indexEnd; k++) {
    currentMax = Math.max(currentMax, array[k])
  }
  return currentMax
}

export function normalize(array: number[], start: number, end: number) {
  const max = findMaximum(array, start, end)

  if (max) {
    for (let k = start; k < end; k++) {
      array[k] /= max
    }
  }
}

export function arrayPower(array: number[], power: number) {
  for (let k = 0; k < array.length; k++) {
    array[k] = Math.pow(array[k], power)
  }
}

export function findMaximumsAndMarkThem(array: number[], maximums: number[], maximumsMarks: number[]) {
  const width = 1

  let maximumsCount = 0

  for (let x = 3; x < array.length - 3; x++) {
    let isGreater = true
    for (let k = -width; k <= width; k++) {
      if (k == 0) continue
      if (array[x] < array[x + k]) {
        isGreater = false
        break
      }
    }

    if (isGreater) {
      maximumsMarks[x] = 1
      for (let k = -1; k <= 1; k++) {
        maximumsMarks[x] += array[x + k]
      }

      maximums[maximumsCount] = x
      maximumsCount++
    }
  }
  return maximumsCount
}
