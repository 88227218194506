import { MathUtils, Quaternion, Vector3 } from 'three'

export class FilterScalar {
  filteredValue = 0
  initialized = false
  lerpDistance = 1

  constructor(lerpDistance: number) {
    this.lerpDistance = lerpDistance
  }

  Filter(value: number) {
    if (!this.initialized) {
      this.filteredValue = value
      this.initialized = true
      return this.filteredValue
    }
    const dist = Math.abs(this.filteredValue - value)
    if (dist > this.lerpDistance) {
      this.filteredValue = value
    } else {
      const lerp = dist / this.lerpDistance
      this.filteredValue = MathUtils.lerp(this.filteredValue, value, lerp)
    }
    return this.filteredValue
  }
}

export class FilterPosition {
  filteredValue = new Vector3()
  initialized = false
  lerpDistance = 1

  constructor(lerpDistance: number) {
    this.lerpDistance = lerpDistance
  }

  Filter(pos: Vector3) {
    if (!this.initialized) {
      this.filteredValue = pos
      this.initialized = true
      return this.filteredValue
    }
    const dist = new Vector3(this.filteredValue.x - pos.x, this.filteredValue.y - pos.y, this.filteredValue.z - pos.z)
    if (dist.length() > this.lerpDistance) {
      this.filteredValue = pos
    } else {
      const lerp = dist.length() / this.lerpDistance
      this.filteredValue.lerp(pos, lerp)
    }
    return this.filteredValue
  }
}

export class FilterRotation {
  filteredValue = new Quaternion()
  initialized = false
  lerpDistance = 1

  constructor(lerpDistance: number) {
    this.lerpDistance = lerpDistance
  }

  Filter(rot: Quaternion) {
    if (!this.initialized) {
      this.filteredValue = rot
      this.initialized = true
      return this.filteredValue
    }
    const dist = this.filteredValue.angleTo(rot)
    if (dist > this.lerpDistance) {
      this.filteredValue = rot
    } else {
      const lerp = dist / this.lerpDistance
      this.filteredValue.slerp(rot, lerp)
    }
    return this.filteredValue
  }
}
