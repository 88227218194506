import { produceObserver } from './observer'

export interface IObservableProperty<T> {
  get value(): T

  set value(value: T)

  subscribe(observer: (value: T) => void): void

  unsubscribe(observer: (value: T) => void): void

  onNextValue(observer: (value: T) => void): Function
}

export function createProperty<Type>(value: Type): IObservableProperty<Type> {
  const { notify, subscribe, unsubscribe } = produceObserver<Type>()

  function set(newValue: Type) {
    if (value !== newValue) {
      value = newValue
      notify(newValue)
    }
  }

  return {
    get value(): Type {
      return value
    },
    set value(value: Type) {
      set(value)
    },
    subscribe,
    unsubscribe,
    onNextValue(observer: (value: Type) => void): Function {
      const innerObserver = (value: Type) => {
        observer(value)
        unsubscribe(innerObserver)
      }

      subscribe(innerObserver)
      return () => unsubscribe(innerObserver)
    },
  }
}
