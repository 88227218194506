import { IEvent } from '../utils/observer'
import Stats from 'three/examples/jsm/libs/stats.module'
import { useEffect, useRef, useState } from 'react'

export interface PerformanceMeterProps {
  title: string
  startedEvent?: IEvent<void>
  completedEvent?: IEvent<void>
}

export function PerformanceMeter(props: PerformanceMeterProps){
  const container = useRef<HTMLDivElement>(null)

  const [stats, setStats] = useState<Stats>()

  useEffect(() => {
    if (container.current) {
      const stats = Stats()
      stats.showPanel(1)
      stats.dom.style.position = 'relative'
      for (const child of stats.dom.children) {
        const element = child as HTMLElement
        if (element) {
          element.style.width = '25vh'
          element.style.height = 'auto'
        }
      }
      container.current.appendChild(stats.dom)
      setStats(stats)
    }
  }, [container])

  useEffect(() => {
    const onRenderStarted = () => stats?.begin()
    const onRenderCompleted = () => stats?.end()

    props.startedEvent?.subscribe(onRenderStarted)
    props.completedEvent?.subscribe(onRenderCompleted)

    return () => {
      props.startedEvent?.unsubscribe(onRenderStarted)
      props.completedEvent?.unsubscribe(onRenderCompleted)
    }
  }, [props, stats])

  return <div ref={container} key={props.title}>
      <h3 style={{color: 'white'}}>{props.title}</h3>
    </div>  
}
