import { MeshBasicMaterial } from 'three'

export function createOccluderMaterial() {
  // return new MeshBasicMaterial({
  //   color: 0x000000,
  //   opacity: 0,    
  //   depthWrite: true,
  // })
  const i = new MeshBasicMaterial();
  i.colorWrite=false;
  return i;
}
