import { Color } from "three"
import { Vector3 } from "three"

export function rgb2hsv(rgb: Color): Vector3 {
    const r = rgb.r
    const g = rgb.g
    const b = rgb.b

    const cMaxB = Math.max(Math.max(r, g), b)
    const cMax = Math.max(Math.max(r, g), b)
    const cMin = Math.min(Math.min(r, g), b)
    const delta = cMax - cMin

    const hsv = new Vector3()

    if (delta == 0) hsv.x = 0
    else if (cMaxB == r) hsv.x = 0.16666666 * (((g - b) / delta) % 6)
    else if (cMaxB == g) hsv.x = 0.16666666 * ((b - r) / delta + 2)
    else if (cMaxB == b) hsv.x = 0.16666666 * ((r - g) / delta + 4)

    if (cMax == 0) hsv.y = 0
    else hsv.y = delta / cMax

    hsv.z = cMax

    return hsv
}

export function rgb2lab(r: number, g: number, b: number): Vector3 {
    var rgb = new Vector3(r, g, b)
    if (rgb.x > 0.04045) rgb.x = 100 * Math.pow((rgb.x + 0.055) / 1.055, 2.4)
    else rgb.x /= 0.1292
    if (rgb.y > 0.04045) rgb.y = 100 * Math.pow((rgb.y + 0.055) / 1.055, 2.4)
    else rgb.y /= 0.1292
    if (rgb.z > 0.04045) rgb.z = 100 * Math.pow((rgb.z + 0.055) / 1.055, 2.4)
    else rgb.z /= 0.1292

    const xyz = new Vector3(
        (rgb.x * 0.4124 + rgb.y * 0.3576 + rgb.z * 0.1805) / 95.047,
        (rgb.x * 0.2126 + rgb.y * 0.7152 + rgb.z * 0.0722) / 100.0,
        (rgb.x * 0.0193 + rgb.y * 0.1192 + rgb.z * 0.9505) / 108.883)

    if (xyz.x > 0.008856) xyz.x = Math.pow(xyz.x, 0.3333333333)
    else xyz.x = (7.787 * xyz.x) + (16 / 116)
    if (xyz.y > 0.008856) xyz.y = Math.pow(xyz.y, 0.3333333333)
    else xyz.y = (7.787 * xyz.y) + (16 / 116)
    if (xyz.z > 0.008856) xyz.z = Math.pow(xyz.z, 0.3333333333)
    else xyz.z = (7.787 * xyz.z) + (16 / 116)

    var lab = new Vector3(
        (116 * xyz.y) - 16,
        500 * (xyz.x - xyz.y),
        200 * (xyz.y - xyz.z))

    return lab

}