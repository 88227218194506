import { PCFSoftShadowMap, sRGBEncoding, WebGLRenderer, WebGLRendererParameters } from 'three'
import { OffscreenCanvas } from 'three/src/renderers/WebGLRenderer'

const rendererParameters: WebGLRendererParameters = {
  antialias: true,
  alpha: true,
  powerPreference: 'high-performance',
  preserveDrawingBuffer: true
}

export function setupRenderer(
  canvas: HTMLCanvasElement | OffscreenCanvas | undefined,
  width: number,
  height: number,
): WebGLRenderer {
  rendererParameters.canvas = canvas
  let renderer = new WebGLRenderer(rendererParameters)
  //renderer.outputEncoding = LinearEncoding
  renderer.shadowMap.enabled = false
  renderer.setSize(width, height)
  // renderer.gammaFactor = 2.2
  renderer.outputEncoding = sRGBEncoding
  return renderer
}
