import '@mediapipe/camera_utils'
import { IEvent, produceObserver } from './utils/observer'

export function createVideoLoadedEvent(video: HTMLVideoElement): IEvent<void> {
  const event = produceObserver<void>()

  function callback() {
    event.notify()
  }

  video.addEventListener('loadeddata', callback)

  return event
}
