import { Matrix4, Quaternion, Vector3 } from 'three'
import { IFaceData } from './ar/virtual-face'

// use this UV visualization as a guide to landmarks
// https://raw.githubusercontent.com/google/mediapipe/master/mediapipe/modules/face_geometry/data/canonical_face_model_uv_visualization.png

const lowerUpVectorLandmarkIndex = 168
const upperUpVectorLandmarkIndex = 10
const leftEyeLandmarkIndex = 362
const rightEyeLandmarkIndex = 133
const leftEarLandmarkIndex = 234
const rightEarLandmarkIndex = 454

export function createFaceTransformFromFaceData(data: IFaceData) {
  const lowerUpVectorLandmark = data.getTransformedPoint(lowerUpVectorLandmarkIndex)
  const upperUpVectorLandmark = data.getTransformedPoint(upperUpVectorLandmarkIndex)
  const leftEyePoint = data.getTransformedPoint(leftEyeLandmarkIndex)
  const rightEyePoint = data.getTransformedPoint(rightEyeLandmarkIndex)
  const leftEarLandmark = data.getTransformedPoint(leftEarLandmarkIndex)
  const rightEarLandmark = data.getTransformedPoint(rightEarLandmarkIndex)

  const upVector = (upperUpVectorLandmark.clone().sub(lowerUpVectorLandmark)).normalize()
  const eyesPositionDifference = leftEyePoint.clone().sub(rightEyePoint)
  const leftVector = eyesPositionDifference.clone().normalize()
  const faceDirection = leftVector.clone().cross(upVector)

  const rotationMatrix = new Matrix4().lookAt(
    new Vector3(0, 0, 0),
    faceDirection,
    upVector,
  )

  const position = leftEarLandmark.clone().lerp(rightEarLandmark, .5)
  const rotation = new Quaternion().setFromRotationMatrix(rotationMatrix)

  function distance(i1: number, i2: number) {
    return data.getTransformedPoint(i1).distanceTo(data.getTransformedPoint(i2))
  }

  const distancesToCalculateScale = [
    distance(127, 356),
    distance(133, 362),
    distance(10, 152),
    distance(67, 149),
    distance(297, 378),
  ]

  const faceScale = distancesToCalculateScale.reduce((a, b) => a + b, 0) / distancesToCalculateScale.length

  return {
    position,
    rotation,
    scale: new Vector3().setScalar(faceScale),
  }
}

export function createFaceTransform(
  lowerUpVectorLandmark: Vector3,
  upperUpVectorLandmark: Vector3,
  leftEyePoint: Vector3,
  rightEyePoint: Vector3,
  leftEarLandmark: Vector3,
  rightEarLandmark: Vector3,
) {
  const upVector = (upperUpVectorLandmark.clone().sub(lowerUpVectorLandmark)).normalize()
  const eyesPositionDifference = leftEyePoint.clone().sub(rightEyePoint)
  const leftVector = eyesPositionDifference.clone().normalize()
  const faceDirection = leftVector.clone().cross(upVector)

  const rotationMatrix = new Matrix4().lookAt(
    new Vector3(0, 0, 0),
    faceDirection,
    upVector,
  )

  const position = leftEarLandmark.clone().lerp(rightEarLandmark, .5)
  const rotation = new Quaternion().setFromRotationMatrix(rotationMatrix)
  const scale = new Vector3().setScalar(eyesPositionDifference.length())

  return {
    position,
    rotation,
    scale,
  }
}
