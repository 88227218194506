import { IHandData } from './virtual-hand'
import { MathUtils, Matrix4, Object3D, Quaternion, Vector3 } from 'three'
import { FilterPosition, FilterRotation, FilterScalar } from '../filters'
import { IEvent } from '../utils/observer'
import { IObservableProperty } from '../utils/observableProperty'

export async function setupRingAR(
  ring: Object3D,
  handUpdatedEvent: IEvent<IHandData>,
  handVisibilityChanged: IEvent<boolean>,
  estimation: IObservableProperty<number>,
  estimationDrift: IObservableProperty<number>,
) {
  let filterScalar = new FilterScalar(0.2)
  let filterPos = new FilterPosition(0.8)
  let filterRot = new FilterRotation(0.4)

  ring.visible = false

  function onHandUpdate(data: IHandData) {
    if (!ring.visible) ring.visible = true

    const fingerStart = data.transformedPoints[13]
    const fingerEnd = data.transformedPoints[14]

    const ringDrift = calculateFingerDrift(data, fingerStart, fingerEnd)
    // TODO: use ring drift

    const normalizedPositionOnFinger = 0.6
    const ringPosition = fingerStart.clone().lerp(fingerEnd, normalizedPositionOnFinger)
    const fixedRingPosition = ringPosition.clone()//.add(ringDrift)
    const filteredRingPosition = filterPos.Filter(fixedRingPosition)
    ring.position.set(filteredRingPosition.x, filteredRingPosition.y, filteredRingPosition.z)

    const scalar = data.scale * estimation.value
    const filteredScalar = filterScalar.Filter(scalar)
    ring.scale.setScalar(filteredScalar)

    const rotation = calculateFingerRotation(data)
    const filteredRotation = filterRot.Filter(rotation)

    //const adjustToScreenRotation = calculateMagnetToScreenRotation(filteredRotation, 1.2)
    ring.quaternion.copy(filteredRotation)

  }

  function calculateFingerDrift(data: IHandData, fingerStart: Vector3, fingerEnd: Vector3) {
    const finger2d = new Vector3(fingerEnd.x - fingerStart.x, fingerEnd.y - fingerStart.y, 0)
    const finger2dOrtho = new Vector3(finger2d.y, -finger2d.x).normalize()
    const widthEstimationBeginLandmark = data.transformedPoints[9]
    const widthEstimationEndLandmark = data.transformedPoints[13]
    const estimateFingerWidth = (new Vector3(widthEstimationBeginLandmark.x, widthEstimationBeginLandmark.y, widthEstimationBeginLandmark.z)
      .distanceTo(new Vector3(widthEstimationEndLandmark.x, widthEstimationEndLandmark.y, widthEstimationEndLandmark.z)))

    const ringDrift = finger2dOrtho.clone().multiplyScalar(estimateFingerWidth * estimationDrift.value)

    return ringDrift
  }

  function onHandVisibilityChange(visible: boolean) {
    ring.visible = visible
  }

  function start() {
    handUpdatedEvent.subscribe(onHandUpdate)
    handVisibilityChanged.subscribe(onHandVisibilityChange)
  }

  function stop() {
    handUpdatedEvent.unsubscribe(onHandUpdate)
    handVisibilityChanged.unsubscribe(onHandVisibilityChange)
  }

  return { start, stop }
}

function calculateFingerRotation(data: IHandData) {
  const startPos = data.transformedPoints[13]
  const endPos = data.transformedPoints[14]
  const rightPos = data.isLeft ? data.transformedPoints[5] : data.transformedPoints[17]
  const leftPos = data.isLeft ? data.transformedPoints[17] : data.transformedPoints[5]

  const rotation = createRotation(startPos, endPos, rightPos, leftPos)

  return rotation
}

function createRotation(startPos: Vector3, endPos: Vector3, rightPos: Vector3, leftPos: Vector3) {
  const forward = endPos.clone().sub(startPos).normalize()
  const right = rightPos.clone().sub(leftPos).normalize()

  const rotationMatrix = new Matrix4().lookAt(
    new Vector3(0, 0, 0),
    forward,
    right,
  )
  const rotation = new Quaternion().setFromRotationMatrix(rotationMatrix)
  return rotation
}

function calculateMagnetToScreenRotation(rot: Quaternion, lerpAngle: number) {
  const tempX = new Vector3(1, 0, 0)
  tempX.applyQuaternion(rot)

  const tempZ = new Vector3(0, 0, 1)
  tempZ.applyQuaternion(rot)

  let projectionAxis = tempZ.clone()
  projectionAxis.crossVectors(tempZ, new Vector3(0, 0, 1))
  projectionAxis.normalize()

  let minusProjection = projectionAxis
  minusProjection.multiplyScalar(projectionAxis.x * tempX.x + projectionAxis.y * tempX.y + projectionAxis.z * tempX.z)

  let needX = tempX.clone().sub(minusProjection)

  const axisAngle = tempX.angleTo(needX)

  let magnetToScreenAdditionalRot = new Quaternion()
  magnetToScreenAdditionalRot.setFromAxisAngle(tempZ, axisAngle)
  let magnetToScreenRot = magnetToScreenAdditionalRot.clone()
  magnetToScreenRot.multiply(rot)

  let angleAlpha = 1 - axisAngle / lerpAngle
  angleAlpha = MathUtils.clamp(angleAlpha, 0, 1)

  let adjustedRot = rot.clone()
  adjustedRot.slerp(magnetToScreenRot, angleAlpha)

  return adjustedRot
}

