import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { Object3D, Vector3 } from 'three'

const linesCount = 8
const widthMultiplier = 0.0285

const connections = [
  [0, 17, 13, 9, 5, 0],
  [17, 18, 19, 20],
  [13, 14, 15, 16],
  [9, 10, 11, 12],
  [5, 6, 7, 8],
  [0, 1, 2, 3, 4],
  [0, 9],
  [0, 13],
]

export interface ILinesHand {
  root: Object3D,
  lines: Line2[],
  update: (points: Vector3[], width: number) => void,
}

export function createOcclusionHand(): ILinesHand {
  const rootObject = new Object3D()

  const data = {
    root: rootObject,
    lines: [...Array(linesCount).keys()].map(() => createLine(rootObject, createOccluderMaterial())),
    update: updateHand,
  }

  function updateHand
  (
    points: Vector3[],
    width: number,
  ) {
    for (let lineIndex = 0; lineIndex < data.lines.length; lineIndex++) {
      const line = data.lines[lineIndex]

      line.material.linewidth = width * widthMultiplier

      const flatPositions = connections[lineIndex].flatMap(i => points[i].toArray())
      line.geometry.setPositions(flatPositions)
    }
  }

  return data
}

function createLine(rootObject: Object3D, material: LineMaterial) {
  let geometry = new LineGeometry()
  const line = new Line2(geometry, material)
  rootObject.add(line)

  return line
}

function createOccluderMaterial() {
  return new LineMaterial({
    color: 0x000000,
    linewidth: 0,
    dashed: false,
    opacity: 0.0,
    depthWrite: true,
  })
}



