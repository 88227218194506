import '@mediapipe/pose'
import { Pose, Results } from '@mediapipe/pose'
import { ITrackingGraph } from './types'
import { createTrackingGraph } from './tracking-essential'

export function createPoseTrackingGraph(): ITrackingGraph<Results> {
  const graph = new Pose({
    locateFile: (file: string) => `https://testingcf.jsdelivr.net/npm/@mediapipe/pose/${file}`,
  })

  graph.setOptions({
    modelComplexity: 1,
    smoothLandmarks: true,
    enableSegmentation: false,
    smoothSegmentation: false,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
  })

  return createTrackingGraph<Results>(graph)
}
