import { CameraDirection, ICamera, startCamera } from './camera'
import { once } from './utils/observer'
import { createVideoLoadedEvent } from './events'
import { RefObject, useEffect, useState } from 'react'

export function useCamera(
  video: RefObject<HTMLVideoElement>,
  defaultCameraDirection: CameraDirection) {
  const [isLoading, setIsLoading] = useState(true)
  const [cameraDirection, setCameraDirection] = useState(defaultCameraDirection)
  const [cameraWasRequested, setCameraWasRequested] = useState(false)
  const [camera, setCamera] = useState<ICamera | undefined>()

  useEffect(() => {
    if (!video.current || !cameraWasRequested) return

    setIsLoading(true)

    const camera = startCamera(video.current, cameraDirection)
    setCamera(camera)

    once(createVideoLoadedEvent(video.current), () => setIsLoading(false))

    return function cleanup() {
      setCamera(undefined)
      camera.stopCamera()
    }
  }, [video, cameraDirection, cameraWasRequested])

  return {
    camera,
    cameraDirection,
    setCameraDirection,
    setCameraWasRequested,
    isLoading,
  }
}
