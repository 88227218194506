import React from 'react';
import ReactDOM from 'react-dom/client';
import { attributeIsSetted, observeAttribute } from './attributeHelpers';
import CodeSarlWrapper from './Components/code-sarl-wrapper'
import reportWebVitals from './reportWebVitals';
import { getJewelryPath } from './getJewelryPath';
import styles from './styles.module.css';
import '@google/model-viewer';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      'ar-modes': string;
      'shadow-intensity': string;
      'camera-controls': boolean;

    }
  }
}

const HIDE_ATTRIBUTE_NAME = "hide";
const htmlElem = document.getElementById('code-sarl-widget') as HTMLElement
const root = ReactDOM.createRoot(htmlElem);


function render() {

  root.render(
    <React.StrictMode>
      <AppWrapper isARMode={false}></AppWrapper>
    </React.StrictMode>
  );
}

type AppWrapperState = {
  isARMode: boolean; // like this
};
class AppWrapper extends React.Component<AppWrapperState> {
  state: AppWrapperState = {
    // optional second annotation for better type inference
    isARMode: false,
  };
  render() {
    // console.log("THE FUCK IS HAPPENING!")
    if (this.state.isARMode) {
      return <CodeSarlWrapper hide={attributeIsSetted(htmlElem, HIDE_ATTRIBUTE_NAME)} />;
    }
    return (
      <div>
        <div className={styles.go_ar_button_wrapper}>
          <button className={styles.go_ar_button} onClick={this.goARMode}>
            <img width={50} height={20} src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt='GO AR MODE' />
          </button>
        </div>
        <model-viewer src={getJewelryPath('ring')} ar-modes="webxr scene-viewer quick-look" shadow-intensity='1' camera-controls></model-viewer>
      </div>

    );
  }

  goARMode = () => {
    this.setState((state) => ({
      isARMode: true
    }))
  }
}
observeAttribute(htmlElem, HIDE_ATTRIBUTE_NAME, render)
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

