import { ITrackingGraph } from './types'
import { createProperty } from '../utils/observableProperty'
import { produceObserver } from '../utils/observer'

export interface InputMap {
  image: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement;
}

export interface IMediapipeGraph<TResults, TOptions> {
  initialize(): Promise<void>

  close(): Promise<void>

  send(inputs: InputMap): Promise<void>

  setOptions(options: TOptions): void

  reset(): void

  onResults(listener: (results: TResults) => (Promise<void> | void)): void;

}

export function createTrackingGraph<TResults>(graph: IMediapipeGraph<TResults, any>): ITrackingGraph<TResults> {
  const initialized = createProperty(false)
  let graphBlocked = false

  const { notify, subscribe, unsubscribe } = produceObserver<TResults>()

  function onResults(results: TResults) {
    if (!initialized.value) {
      initialized.value = true
    }
    notify(results)
  }

  async function sendVideo(video: HTMLVideoElement) {
    if (graphBlocked) {
      return
    }
    graphBlocked = true
    await graph.send({ image: video })
    graphBlocked = false
  }

  async function stop() {
    graphBlocked = true
    await graph.close()
    graphBlocked = false
  }

  graph.onResults(onResults)

  return {
    initialized,
    resultsEvent: { subscribe, unsubscribe },
    sendVideo,
    stop,
  }
}
