import { Euler, MathUtils, Matrix4, Object3D, Quaternion, Vector3 } from 'three'
import { IEvent } from '../utils/observer'
import { IPoseData } from './virtual-pose'
import { FilterPosition, FilterRotation, FilterScalar } from '../filters'

function createRotation(startPos: Vector3, endPos: Vector3, rightPos: Vector3, leftPos: Vector3) {
  const forward = endPos.clone().sub(startPos).normalize()
  const right = rightPos.clone().sub(leftPos).normalize()

  const rotationMatrix = new Matrix4().lookAt(
    new Vector3(0, 0, 0),
    forward,
    right,
  )
  const rotation = new Quaternion().setFromRotationMatrix(rotationMatrix)
  return rotation
}

export function createNecklaceAR(modelRoot: Object3D, updatedEvent: IEvent<IPoseData>, visibilityChangedEvent: IEvent<boolean>) {
  const filterScalar = new FilterScalar(0.2)
  const filterPos = new FilterPosition(0.8)
  const filterRot = new FilterRotation(0.2)

  const necklaceRotationOffset = new Quaternion().setFromEuler(new Euler().set(
    0,
    MathUtils.degToRad(-20),
    0,
  ))

  modelRoot.visible = false
  updatedEvent.subscribe(onPoseData)
  visibilityChangedEvent.subscribe(onVisibilityChanged)

  function onPoseData(data: IPoseData) {
    modelRoot.visible = true

    const shoulderRight = data.transformedPoints[12]
    const shoulderLeft = data.transformedPoints[11]

    const hipRight = data.transformedPoints[24]
    const hipLeft = data.transformedPoints[23]

    const midShoulders = shoulderRight.clone().lerp(shoulderLeft, 0.5)
    const midHips = hipRight.clone().lerp(hipLeft, 0.5)
    const shoulderToHipsHeight = midShoulders.distanceTo(midHips)

    const position = midShoulders.clone()

    function calculateBodyRotation() {
      const rotationOffset = new Quaternion().setFromEuler(new Euler().set(
        0,
        MathUtils.degToRad(-90),
        MathUtils.degToRad(90),
      ))

      const shoulderRight = data.worldPoints[12]
      const shoulderLeft = data.worldPoints[11]

      const hipRight = data.worldPoints[24]
      const hipLeft = data.worldPoints[23]

      const midShoulders = shoulderRight.clone().lerp(shoulderLeft, 0.5)
      const midHips = hipRight.clone().lerp(hipLeft, 0.5)

      return createRotation(shoulderLeft, shoulderRight, midShoulders, midHips).multiply(rotationOffset)
    }

    const bodyRotation = calculateBodyRotation()

    // move forward
    position.add(new Vector3(0, 0, -1)
      .applyQuaternion(bodyRotation)
      .normalize()
      .multiplyScalar(shoulderToHipsHeight * 0.15))

    const rotation = bodyRotation.clone().multiply(necklaceRotationOffset)
    const scale = shoulderToHipsHeight * 0.007

    modelRoot.position.copy(filterPos.Filter(position))
    modelRoot.quaternion.copy(filterRot.Filter(rotation))
    modelRoot.scale.setScalar(filterScalar.Filter(scale))
  }

  function onVisibilityChanged(visible: boolean) {
    modelRoot.visible = visible
  }

  function stop() {
    updatedEvent.unsubscribe(onPoseData)
    visibilityChangedEvent.unsubscribe(onVisibilityChanged)
  }

  return {
    stop,
  }
}
