import { IHandData } from './ar/virtual-hand'
import { IEvent } from './utils/observer'
import { Color, MathUtils, Vector2, Vector3 } from 'three'
import { rgb2hsv } from './image-processing/convert'
import { getPixel } from './image-processing/array'
import { createProperty } from './utils/observableProperty'
import {
  arrayPower as powerArray,
  fillColumnColorDerivatives,
  filterMean,
  findMaximumsAndMarkThem,
  getMaxNeighbourDistance as calculateMaxNeighborDistance,
  normalize as normalizeArray,
} from './image-processing/algorithms'

const estimationWidthKoeff = 0.90

export function createFingerBorderEstimation(handUpdated: IEvent<IHandData>) {
  const sliceWidth = 50
  const sliceHeight = 20

  const _columnColors: Vector3[] = []
  const _sliceColumnDerivatives: number[] = []
  const _sliceTextureColors: Color[] = []
  const _sliceColumnDerivativesFiltered: number[] = []
  const _maximums: number[] = []
  const _maximumsMarks: number[] = []

  let pixelData: Uint8Array | undefined

  let lastBordersCenter: number = 0
  let lastBorderLeft: number = 0
  let lastBorderRight: number = 0

  let _bordersDistanceMark: number[] = new Array(sliceWidth)
  let bordersDistanceMarkPoint1 = Math.round(sliceWidth * 0.35)
  let bordersDistanceMarkPoint2 = Math.round(sliceWidth * 0.45)
  let bordersDistanceMarkPoint3 = Math.round(sliceWidth * 0.75)
  let bordersDistanceMarkPoint4 = Math.round(sliceWidth * 0.85)
  for (let k = bordersDistanceMarkPoint1; k < bordersDistanceMarkPoint2; k++) {
    let alpha = (k - bordersDistanceMarkPoint1) / (bordersDistanceMarkPoint2 - bordersDistanceMarkPoint1)
    _bordersDistanceMark[k] = alpha
  }

  for (let k = bordersDistanceMarkPoint2; k < bordersDistanceMarkPoint3; k++) {
    _bordersDistanceMark[k] = 1
  }

  for (let k = bordersDistanceMarkPoint3; k < bordersDistanceMarkPoint4; k++) {
    let alpha = (k - bordersDistanceMarkPoint3) / (bordersDistanceMarkPoint4 - bordersDistanceMarkPoint3)
    _bordersDistanceMark[k] = 1 - alpha
  }

  let lastRingPoint = new Vector2(0, 0)
  let smoothedVelocity = new Vector2(0, 0)

  function CheckGoodVelocity(ringPoint: Vector2) {
    smoothedVelocity = smoothedVelocity.lerp(new Vector2(ringPoint.x - lastRingPoint.x, ringPoint.y - lastRingPoint.y), 0.9)
    lastRingPoint = ringPoint
    //console.log(smoothedVelocity.length())
    if (smoothedVelocity.length() > 0.01)
      return false
    return true
  }

  function onHandUpdate(hand: IHandData) {
    const landmarks = hand.rawData.multiHandLandmarks[0]
    const canvas = hand.rawData.image as HTMLCanvasElement

    const context = canvas.getContext('webgl2')

    if (!pixelData) pixelData = new Uint8Array(canvas.width * canvas.height * 4)
    if (!context) return

    const startLandmark = landmarks[13]
    const endLandmark = landmarks[14]

    const widthEstimationBeginLandmark = landmarks[9]
    const widthEstimationEndLandmark = landmarks[13]

    const fingerStart = new Vector2(startLandmark.x, 1 - startLandmark.y)
    const fingerEnd = new Vector2(endLandmark.x, 1 - endLandmark.y)
    const estimateFingerWidth = (new Vector3(widthEstimationBeginLandmark.x, widthEstimationBeginLandmark.y, widthEstimationBeginLandmark.z)
      .distanceTo(new Vector3(widthEstimationEndLandmark.x, widthEstimationEndLandmark.y, widthEstimationEndLandmark.z)))

    const fingerVector = fingerEnd.clone().sub(fingerStart)

    var fingerForwardDirection2D = fingerVector.clone().normalize()
    var fingerOrthoDirection2D = new Vector2(-fingerForwardDirection2D.y, fingerForwardDirection2D.x).normalize()

    // Palm vector from Pinky to Index in normalized 3d-screenspace
    var palmHorizontalDirection = (hand.worldPoints[5].clone().sub(hand.worldPoints[17])).normalize()

    // If palm is rotated in depth - dont try to analyse it
    if (Math.abs(palmHorizontalDirection.z) > 0.6) return
    // If finger is rotated in depth - dont try to analyse it
    if (Math.abs((hand.worldPoints[13].clone().sub(hand.worldPoints[14])).normalize().z) > 0.5) return

    const ringPoint = fingerStart.clone().lerp(fingerEnd, 0.7)

    if (!CheckGoodVelocity(ringPoint))
      return

    const sliceUp = fingerForwardDirection2D.clone().multiplyScalar(estimateFingerWidth * estimationWidthKoeff * 0.5)//.multiply(ratioVector)
    const sliceRight = fingerOrthoDirection2D.clone().multiplyScalar(estimateFingerWidth * estimationWidthKoeff)//.multiply(ratioVector)

    context.readPixels(0, 0, canvas.width, canvas.height, context.RGBA, context.UNSIGNED_BYTE, pixelData)

    fillColorsSliceFromNativeTexture(
      pixelData,
      canvas.width,
      canvas.height,
      ringPoint,
      sliceUp,
      sliceRight,
      _sliceTextureColors,
      sliceWidth,
      sliceHeight,
    )

    fillColumnsHSV(_sliceTextureColors, sliceWidth, sliceHeight, _columnColors, 1, 1, 0.1)

    const maxLABDistance = calculateMaxNeighborDistance(_columnColors)

    fillColumnColorDerivatives(_sliceTextureColors, sliceWidth, sliceHeight, _sliceColumnDerivatives)


    filterMean(_sliceColumnDerivatives, _sliceColumnDerivativesFiltered, 2)
    normalizeArray(_sliceColumnDerivativesFiltered, 0, _sliceColumnDerivativesFiltered.length)

    powerArray(_sliceColumnDerivativesFiltered, 0.5)


    const maximumsCount = findMaximumsAndMarkThem(_sliceColumnDerivativesFiltered, _maximums, _maximumsMarks)

    const border = calculateBorders()


    // FILTERING RESULT SCALE
    var newScaleLocal = (border.rightBorder - border.leftBorder)
    if (newScaleLocal / sliceWidth > 0.4 && newScaleLocal / sliceWidth < 0.75) {
      const sliceFingerWidth = sliceWidth * (1 / (estimationWidthKoeff * 2))
      var newScaleGlobal = newScaleLocal / sliceFingerWidth
      estimation.value = MathUtils.lerp(estimation.value, newScaleGlobal, 0.15)
      const drift = ((border.rightBorder + border.leftBorder) * 0.5 - sliceWidth*0.5) / sliceFingerWidth
      estimationDrift.value = MathUtils.lerp(estimationDrift.value, drift, 0.2)
    }

    lastBorderLeft = border.leftBorder
    lastBorderRight = border.rightBorder
    lastBordersCenter = border.bordersCenterX

    function calculateBorders() {
      let leftBorder = 0
      let rightBorder = 0
      let bestBordersMark = 0

      for (let b1 = 0; b1 < maximumsCount - 1; b1++) {
        for (let b2 = b1 + 1; b2 < maximumsCount; b2++) {
          // Center mean color
          let centerPosition = Math.floor((_maximums[b1] + _maximums[b2]) / 2)
          let centerMeanColor = new Vector3()
          for (let k = -2; k <= 2; k++) {
            const color = _columnColors[centerPosition + k]
            if (color) centerMeanColor.add(color)
          }
          centerMeanColor.multiplyScalar(1 / 5)

          let bordersMark = _maximumsMarks[_maximums[b1]] + _maximumsMarks[_maximums[b2]]
          let distance = (_maximums[b2] - _maximums[b1])
          bordersMark *= _bordersDistanceMark[distance]

          if (_bordersDistanceMark[distance] > 0) {
            // Calculating maximum deviation between extremums. If more - less mark
            let maximumDevitionBetweenBorders = 0
            for (let b = b1 + 1; b < b2; b++) {
              if (_maximumsMarks[_maximums[b]] > maximumDevitionBetweenBorders) {
                maximumDevitionBetweenBorders = _maximumsMarks[_maximums[b]]
              }
            }

            let deviationsBetweenBordersKoeff = 1 - 0.7 * maximumDevitionBetweenBorders / MathUtils.lerp(_maximumsMarks[_maximums[b1]], _maximumsMarks[_maximums[b2]], 0.5)

            bordersMark *= deviationsBetweenBordersKoeff

            // Calculating color distance between borders. If more - less mark
            let maxLocalLABDistace = 0
            for (let i = _maximums[b1] + 1; i < _maximums[b2] - 3; i++) {
              for (let j = i; j < _maximums[b2] - 3; j++) {
                let dist = _columnColors[i].distanceTo(_columnColors[j])
                if (dist > maxLocalLABDistace) maxLocalLABDistace = dist
              }
            }

            bordersMark *= 1 - 0.25 * maxLocalLABDistace / maxLABDistance
          }

          if (bordersMark > bestBordersMark) {
            leftBorder = _maximums[b1]
            rightBorder = _maximums[b2]
            bestBordersMark = bordersMark
          }
        }
      }

      if (leftBorder > 0)
        leftBorder -= 1

      let bordersCenterX = (rightBorder + leftBorder) * 0.5
      if (lastBordersCenter != 0) {
        let drift1 = leftBorder - lastBorderLeft
        let drift2 = rightBorder - lastBorderRight
        let minDrift = drift1
        if (Math.abs(drift2) < Math.abs(drift1)) minDrift = drift2
        bordersCenterX = MathUtils.lerp(lastBordersCenter + minDrift, bordersCenterX, 0.1)
      }

      return { leftBorder, rightBorder, bordersCenterX }
    }

    for (let y = 0; y < sliceHeight; y++) {
      _sliceTextureColors[border.leftBorder + y * sliceWidth].lerp(new Color(0, 1, 0), 0.5)
      _sliceTextureColors[border.rightBorder + y * sliceWidth].lerp(new Color(0, 1, 0), 0.5)
    }

    if (false) { //debug draw
      const outputCanvas = document.getElementById('debug-canvas') as HTMLCanvasElement
      outputCanvas.width = sliceWidth
      outputCanvas.height = sliceHeight
      outputCanvas.style.scale = '5'
      putColorsOnCanvas(outputCanvas, _sliceTextureColors, sliceWidth, sliceHeight)
    }
  }

  let estimation = createProperty<number>(1)
  let estimationDrift = createProperty<number>(0)

  function start() {
    handUpdated.subscribe(onHandUpdate)
  }

  function stop() {
    handUpdated.unsubscribe(onHandUpdate)
  }

  return { estimation, estimationDrift, start, stop }
}


function fillColorsSliceFromNativeTexture(
  sourceTexture: Uint8Array,
  sourceWidth: number,
  sourceHeight: number,
  sliceCenterPoint: Vector2,
  sliceUp: Vector2,
  sliceRight: Vector2,
  sliceColors: Color[],
  sliceWidth: number,
  sliceHeight: number) {

  const leftBorder = sliceCenterPoint.clone().sub(sliceRight)
  const rightBorder = sliceCenterPoint.clone().add(sliceRight)

  for (let w = 0; w < sliceWidth; w++) {
    const x = w / (sliceWidth - 1)

    const horizontal = leftBorder.clone().lerp(rightBorder, x)
    const upperBorder = horizontal.clone().add(sliceUp)
    const lowerBorder = horizontal.clone().sub(sliceUp)

    for (let h = 0; h < sliceHeight; h++) {
      const y = h / (sliceHeight - 1)

      const position = upperBorder.clone().lerp(lowerBorder, y)

      if (position.distanceTo(sliceCenterPoint) < sliceUp.length() / 15) {
        sliceColors[w + h * sliceWidth] = new Color(1, 0, 0)
        continue
      }

      const pixelX = Math.round(position.x * sourceWidth)
      const pixelY = Math.round(position.y * sourceHeight)

      const pixel = getPixel(sourceTexture, sourceWidth, pixelX, pixelY)
      sliceColors[w + h * sliceWidth] = pixel
    }
  }

  return

  const startPoint = sliceCenterPoint.clone().sub(sliceRight)
  const endPoint = sliceCenterPoint.clone().add(sliceRight)


  for (let k = 0; k < sliceWidth; k++) {
    const alpha = k / (sliceWidth - 1)

    const position = startPoint.clone().lerp(endPoint, alpha)

    // Up and down of slice texture
    const startY = position.clone().sub(sliceUp)
    const endY = position.clone().add(sliceUp)

    for (let i = 0; i < sliceHeight; i++) {
      const alphaI = i / (sliceHeight - 1)
      const yPosition = startY.clone().lerp(endY, alphaI)

      if (yPosition.distanceTo(sliceCenterPoint) < sliceUp.length() / 15) {
        sliceColors[k + i * sliceWidth] = new Color(1, 0, 0)
        continue
      }
      const x = Math.round(yPosition.x * sourceWidth)
      const y = Math.round(yPosition.y * sourceHeight)

      const pixel = getPixel(sourceTexture, sourceWidth, x, y)

      sliceColors[k + i * sliceWidth] = pixel
    }
  }
}

function putColorsOnCanvas(canvas: HTMLCanvasElement, colors: Color[], width: number, height: number) {
  const context = canvas.getContext('2d')
  if (!context) {
    // console.log('[putColorsOnCanvas] Canvas context is not defined')
    return
  }
  const imageData = context.createImageData(width, height)
  const data = imageData.data

  for (let i = 0; i < colors.length; i++) {
    const color = colors[i]


    const index = i * 4
    data[index] = color.r * 255
    data[index + 1] = color.g * 255
    data[index + 2] = color.b * 255
    data[index + 3] = 255
  }
  context.putImageData(imageData, 0, 0)
}


function fillColumnsHSV(colors: Color[], width: number, height: number, destination: Vector3[], k1: number, k2: number, k3: number) {
  for (let x = 0; x < width; x++) {
    const mean = new Vector3(0, 0, 0)
    for (let y = 0; y < height; y++) {
      const pixel = colors[x + y * width]

      const hsv = rgb2hsv(pixel)
      mean.add(new Vector3(k1 * hsv.x, k2 * hsv.y, k3 * hsv.z))
    }

    mean.multiplyScalar(1 / height)
    destination[x] = mean
  }
}
