
import CodeSarlWidget from './code-sarl-widget'
import { JewelryTypeName } from '../utils/jewelries-enums'
import { useState } from 'react'

const defaultJewelryId = 'ring'
const defaultJewelryType = JewelryTypeName.Ring

function CodeSarlWrapper({hide}:{hide?:boolean}) {  
  // console.log(`Render TrillionWrapper with hide = ${hide}`)

  const [jewelryId] = useState(() => {
    const url = new URL(window.location.href)
    return url.searchParams.get('id') ?? defaultJewelryId
  })

  const [jewelryType] = useState<JewelryTypeName>(() => {
    const url = new URL(window.location.href)
    return url.searchParams.get('type') as JewelryTypeName ?? defaultJewelryType
  })

  const [usePerformanceCheck] = useState(() => window.location.search.includes('performance'))

  return <div>
    {!hide && <CodeSarlWidget 
      tokenId='none' 
      jewelryId={jewelryId} 
      jewelryType={jewelryType} 
      usePerformanceCheck={usePerformanceCheck}
      />}
    </div>
}

export default CodeSarlWrapper
