import { createVirtualFace, IFaceData } from '../ar/virtual-face'
import { setupEarringAR } from '../ar/earring-ar'
import { drawFaceMeshResultsOnCanvas, drawImage } from '../tracking/drawing'
import { createFaceMeshTrackingGraph } from '../tracking/face-tracking-graph'
import { IRenderer3D } from '../object-render'
import { BufferGeometry, Mesh, Object3D, Vector3 } from 'three'
import { importEarring, importJewelry } from '../import-jewelry'
import { createCube } from '../utils/three-primitives'
import { getJewelryPath } from '../getJewelryPath'
import { createMultiProgress } from '../create-multi-progress'
import { ITryOnSession } from './types'
import { createProperty } from '../utils/observableProperty'
import { ICameraFrame } from '../camera'
import { createOccluderMaterial } from '../utils/materials'
import { createARFaceMesh } from '../ar/face-mesh-ar'

export async function startEarringTryOn(
  jewelryId: string,
  arRenderer: IRenderer3D,
  landmarksCanvas: HTMLCanvasElement,
  progressCallback: (progress: number) => void): Promise<ITryOnSession> {
  const currentProgress = createMultiProgress([5,5])
  currentProgress.progressChangedEvent.subscribe(progressCallback)

  const graph = createFaceMeshTrackingGraph()

  const videoFlipped = createProperty(false)

  const { isVisible, updateEvent } = createVirtualFace(graph, arRenderer.camera, videoFlipped)

  const jewelryPath = getJewelryPath(jewelryId)

  const modelRoots = [new Object3D(), new Object3D()]

  if (jewelryPath) {
    importEarring(jewelryPath, onJewelryImportProgress).then(model => {
      modelRoots.forEach(root => root.add(model.parent ? model.clone(true) : model))
    })
  } else {
    modelRoots[0].add(createCube(.25, 0x00ff00))
    modelRoots[1].add(createCube(.25, 0x0000ff))
  }

  function onJewelryImportProgress(event: ProgressEvent) {
    const progress = event.loaded / event.total
    currentProgress.update(0, progress)
  }

  graph.initialized.onNextValue(() => {
    currentProgress.update(1, 1)
  })

  const earringsAR = [
    setupEarringAR(modelRoots[0], true, updateEvent, isVisible),
    setupEarringAR(modelRoots[1], false, updateEvent, isVisible),
  ]

  const faceMesh = new Mesh(new BufferGeometry(), createOccluderMaterial())
  const arFaceMesh = await createARFaceMesh(faceMesh, updateEvent, isVisible)

  arRenderer.scene.add(...modelRoots, faceMesh)

  earringsAR.forEach(earring => earring.start())

  graph.resultsEvent.subscribe(results => {
    drawImage(landmarksCanvas, results, false)
    if (landmarksCanvas.dataset.showLandmarks == 'true') {
      drawFaceMeshResultsOnCanvas(landmarksCanvas, results)
    }
    arRenderer.render()
  })

  function stop() {
    graph.stop()
    arFaceMesh.stop()
    earringsAR.forEach(earring => earring.stop())
    arRenderer.scene.remove(...modelRoots, faceMesh)
  }

  function onCameraFrame(frame: ICameraFrame) {
    videoFlipped.value = frame.facingMode == 'user'
    graph.sendVideo(frame.videoElement)
  }

  return {
    initialized: graph.initialized,
    sendVideoCallback: onCameraFrame,
    stop,
  }
}
