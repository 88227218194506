//@ts-ignore
import styles from '../styles.module.css'
import { initRenderer, IRenderer3D } from '../object-render'
import { CameraDirection, ICameraFrame } from '../camera'
import { startRingTryOn } from '../try-on-sessions/ring-try-on'
import { startEarringTryOn } from '../try-on-sessions/earring-try-on'
import { LoaderComponent } from './loader-component'
import { createMultiProgress } from '../create-multi-progress'
import { IEvent, INotifier, once, produceObserver } from '../utils/observer'
import { PerformanceGroup, PerformanceGroupProps } from './performance-group'
import { createVideoLoadedEvent } from '../events'
import { isMobileOrTablet } from '../utils/device-utils'
import { currentCameraZoom } from '../zoom'
import { frontCameraZoom, rearCameraZoom } from '../constants'
import { useCamera } from '../camera-hooks'
import { SwitchCameraButton } from './switch-camera-button'
import { ScreenShotButton } from "./screnshot-button"
import { ITryOnSession } from '../try-on-sessions/types'
import { startNecklaceTryOn } from '../try-on-sessions/necklace-try-on'
import { JewelryTypeName } from '../utils/jewelries-enums'
import { CameraExistError } from './cameraExistError'
import { videoResolution } from '../constants'
import { useEffect, useRef, useState } from 'react'

interface AppEvents {
  cycleStartedEvent: IEvent<void> & INotifier<void>,
  cycleCompletedEvent: IEvent<void> & INotifier<void>,
  renderStartedEvent: IEvent<void> & INotifier<void>,
  renderCompletedEvent: IEvent<void> & INotifier<void>,
}

export function CodeSarlWidget 
  ({
     tokenId,
     jewelryId,
     jewelryType,
     usePerformanceCheck,
   }: {
    tokenId: string,
    jewelryId: string,
    jewelryType: JewelryTypeName,
    usePerformanceCheck: boolean,
  }){
    const container = useRef<HTMLDivElement>(null)
    const video = useRef<HTMLVideoElement>(null)
    const canvas1 = useRef<HTMLCanvasElement>(null)
    const canvas2 = useRef<HTMLCanvasElement>(null)


    const [size, setSize] = useState({ height: 0, width: 0 })
    const [showPerformance, setShowPerformance] = useState(usePerformanceCheck)
    const [progress, setProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(true)    
    const [isCamera, setIsCamera] = useState(true)
    const [isScreenshotCanvasActive, setIsScreenshotCanvasActive] = useState(false)
  
    const showLandmarks = useKeyDown('KeyL')
    const performanceKeyToggled = useKeyDown('KeyF')

    const [events, setEvents] = useState<AppEvents>()
    const defaultDirection = isMobileOrTablet() ? (jewelryType === JewelryTypeName.Ring ? CameraDirection.Environment : CameraDirection.User) : CameraDirection.User
    const [sendCameraFrame, setSendCameraFrame] = useState<((arg0: ICameraFrame) => void) | undefined>()

    const {
      camera,
      cameraDirection,
      setCameraDirection,
      setCameraWasRequested,
      isLoading: cameraIsLoading,
    } = useCamera(video, defaultDirection)

    useEffect(() => {
      if (!camera || !sendCameraFrame) return

      function sendFrame(frame: ICameraFrame) {        
        sendCameraFrame?.(frame)
      }

      camera.onCameraFrameEvent.subscribe(sendFrame)
      return () => camera.onCameraFrameEvent.unsubscribe(sendFrame)
    }, [camera, sendCameraFrame])

    useEffect(() => {
      if (cameraDirection === CameraDirection.User) {
        currentCameraZoom.value = frontCameraZoom
      } else if (cameraDirection === CameraDirection.Environment) {
        currentCameraZoom.value = rearCameraZoom
      }
    }, [cameraDirection])

    useEffect(() => {
      if (!video.current) {
        console.error('video is not defined')
        return
      }

      interface IEffectState {
        tryOn?: ITryOnSession
        renderer?: IRenderer3D
      }

      const effectState: IEffectState = {}

      const events = {
        cycleStartedEvent: produceObserver<void>(),
        cycleCompletedEvent: produceObserver<void>(),
        renderStartedEvent: produceObserver<void>(),
        renderCompletedEvent: produceObserver<void>(),
      }
      setEvents(events)

      const multiProgress = createMultiProgress([2,8])
      multiProgress.setAutoIncrement(1, 0.005)

      once(createVideoLoadedEvent(video.current), async () => {
        if (!canvas1.current || !canvas2.current) {
          console.error('canvases are not defined')
          return
        }

        const width = video?.current?.videoWidth || 0
        const height = video?.current?.videoHeight || 0
        setSize({ width, height })

        multiProgress.progressChangedEvent.subscribe(setProgress)

        effectState.renderer = initRenderer({
          width,
          height,
          canvas: canvas2.current,
          progressCallback: progress => multiProgress.update(0, progress),
        })

        const render = effectState.renderer.render
        effectState.renderer.render = () => {
          events.renderStartedEvent.notify()
          render()
          events.renderCompletedEvent.notify()

          events.cycleCompletedEvent.notify()
          events.cycleStartedEvent.notify()
        }

        effectState.tryOn = await (() => {
          switch (jewelryType) {
            case JewelryTypeName.Ring:
              return startRingTryOn(
                jewelryId,
                effectState.renderer!,
                canvas1.current!,
                progress => multiProgress.update(1, progress),
              )
            case JewelryTypeName.Earrings:
              return startEarringTryOn(
                jewelryId,
                effectState.renderer!,
                canvas1.current!,
                progress => multiProgress.update(1, progress),
              )
            case JewelryTypeName.Necklace:
              return startNecklaceTryOn(
                jewelryId,
                effectState.renderer!,
                canvas1.current!,
                progress => multiProgress.update(1, progress),
              )
          }
        })()

        setSendCameraFrame(() => effectState?.tryOn?.sendVideoCallback)

        effectState.tryOn.initialized.onNextValue(() => {
          setProgress(1)
          setIsLoading(false)
        })
      })

      events.cycleStartedEvent.notify()

      return function cleanup() {
        camera?.stopCamera()
        effectState.tryOn?.stop()
        effectState.renderer?.dispose()
      }
    }, [])

    function useKeyDown(keyCode: string) {
      const [keyDown, setKeyDown] = useState<boolean>(false)

      function downHandler(e: KeyboardEvent) {
        if (e.code === keyCode) {          
          setKeyDown(!keyDown)
        }
      }

      useEffect(() => {
        window.addEventListener('keydown', downHandler)
        return () => window.removeEventListener('keydown', downHandler)
      })

      return keyDown
    }

    function createPerformanceGroupProps(): PerformanceGroupProps {
      return {
        blocks: [
          {
            title: 'Total',
            startedEvent: events?.cycleStartedEvent,
            completedEvent: events?.cycleCompletedEvent,
          },
          {
            title: 'Render',
            startedEvent: events?.renderStartedEvent,
            completedEvent: events?.renderCompletedEvent,
          },
        ],
      }
    }

    useEffect(() => {
      navigator.mediaDevices.getUserMedia({video: videoResolution})
        .then((e) => {
          if (!e.active) {
            setIsCamera(false)
          }else
          {
            e.getTracks().forEach(t => {
              // console.log(`Stopping track: ${e}`)
              t.stop();
            }); 
            setCameraWasRequested(true)
          }
        })
        .catch(function(err) {
          console.error(err)
          setIsCamera(false)
        });        
    }, [])

    return <div className={styles.container} ref={container}>
        {isLoading && <LoaderComponent progress={progress} />}
        {!isCamera && <CameraExistError/>}
        {!isLoading && <ScreenShotButton 
          canvas1 = {canvas1.current}
          canvas2 = {canvas2.current}
          width = {size.width}
          height = {size.height}
          setIsScreenshotCanvasActive = {setIsScreenshotCanvasActive}
        />}
        {!isLoading && !isScreenshotCanvasActive && isMobileOrTablet() && <SwitchCameraButton
          cameraIsLoading = {cameraIsLoading}
          cameraDirection = {cameraDirection}
          setCameraDirection = {setCameraDirection}          
        />}
        {(showPerformance ? !performanceKeyToggled : performanceKeyToggled) &&
          <PerformanceGroup {...createPerformanceGroupProps()}/>}
        <video
          ref={video}
          height={size.height}
          width={size.width}
          className={styles.video}
        ></video>
        <canvas
          ref={canvas1}
          height={size.height}
          width={size.width}
          className={styles.canvas1}
          data-show-landmarks={showLandmarks}
          id='canvas1'
        ></canvas>
        <canvas
          ref={canvas2}
          height={size.height}
          width={size.width}
          className={styles.canvas2}
          id='canvas2'
        ></canvas>
      </div>
  }

export default CodeSarlWidget
