import { ITrackingGraph } from '../tracking/types'
import { Results } from '@mediapipe/face_mesh'
import { OrthographicCamera, Vector3 } from 'three'
import { produceObserver } from '../utils/observer'
import { createProperty, IObservableProperty } from '../utils/observableProperty'
import { transformLandmarkToWorldPoint } from '../transformation'

export interface IFaceData {
  pointsCount: number
  getTransformedPoint(index: number): Vector3
}

export function createVirtualFace(
  tracking: ITrackingGraph<Results>,
  camera: OrthographicCamera,
  videoFlipped: IObservableProperty<boolean>) {

  let isVisible = createProperty(false)

  const { notify, subscribe, unsubscribe } = produceObserver<IFaceData>()

  let currentResults: Results

  function getTransformedPoint(index: number): Vector3 {
    const landmark = currentResults.multiFaceLandmarks[0][index]
    return transformLandmarkToWorldPoint(camera, landmark)
  }

  tracking.resultsEvent.subscribe(results => {
    currentResults = results
    if (results?.multiFaceLandmarks[0]?.length > 0) {
      if (isVisible.value === false) {
        isVisible.value = true
      }
    } else {
      if (isVisible.value === true) {
        isVisible.value = false
      }
      return
    }

    notify({ getTransformedPoint, pointsCount: currentResults?.multiFaceLandmarks[0]?.length ?? 0})
  })

  return { isVisible, updateEvent: { subscribe, unsubscribe } }
}

