export interface IEvent<DataType> {
  subscribe(callback: (data: DataType) => void): void;

  unsubscribe(callback: (data: DataType) => void): void;
}

export interface INotifier<DataType> {
  notify(data: DataType): void;
}

export function produceObserver<DataType>(): (IEvent<DataType> & INotifier<DataType>) {
  let observers: Array<(arg0: DataType) => void> = []

  return {
    subscribe: (observer: ((arg0: DataType) => void)) => {
      if (observers.includes(observer)) {
        return console.trace('Observer already subscribed')
      }

      observers.push(observer)
    },
    unsubscribe: (observer: ((arg0: DataType) => void)) => {
      const index = observers.indexOf(observer)
      if (index === -1) {
        return console.trace('Observer not registered')
      }

      observers.splice(index, 1)
    },
    notify: (data: DataType) => {
      for (let observer of [...observers]) {
        observer(data)
      }
    },
  }
}

export function once<DataType>(observer: IEvent<DataType>, callback: (data: DataType) => void) {
  function wrapper(data: DataType) {
    callback(data)
    observer.unsubscribe(wrapper)
  }

  observer.subscribe(wrapper)
}
