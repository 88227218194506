import { Mesh, Vector3 } from 'three'
import { IEvent } from '../utils/observer'
import { IFaceData } from './virtual-face'
import { IObservableProperty } from '../utils/observableProperty'
import axios from 'axios'

interface IFaceMeshData {
  indices: number[]
}

async function loadFaceData() {
  const file = await axios.get(`${process.env.PUBLIC_URL}/assets/faceMeshData.json`, { responseType: 'json' })
  return file.data as IFaceMeshData
}

export async function createARFaceMesh(mesh: Mesh, updateEvent: IEvent<IFaceData>, isVisible: IObservableProperty<boolean>) {
  const points: Vector3[] = []

  const faceMeshData = await loadFaceData()

  mesh.geometry.setIndex(faceMeshData.indices)

  updateEvent.subscribe(onFaceMeshUpdate)
  isVisible.subscribe(onFaceMeshVisibilityChanged)

  function onFaceMeshVisibilityChanged(visible: boolean) {
    return mesh.visible = visible
  }

  function onFaceMeshUpdate(data: IFaceData) {
    for (let i = 0; i < data.pointsCount; i++) {
      points[i] = data.getTransformedPoint(i)
    }
    mesh.geometry.setFromPoints(points)
  }

  function stop() {
    updateEvent.unsubscribe(onFaceMeshUpdate)
    isVisible.unsubscribe(onFaceMeshVisibilityChanged)
  }

  return {
    stop,
  }
}
