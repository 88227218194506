import { NormalizedLandmark, Results } from '@mediapipe/hands'
import { OrthographicCamera, Vector3 } from 'three'
import { produceObserver } from '../utils/observer'
import { ITrackingGraph } from '../tracking/types'
import { transformLandmarkToWorldPoint, transformPointToWorldPoint } from '../transformation'
import { IObservableProperty } from '../utils/observableProperty'

export interface IHandData {
  get rawData(): Results

  get transformedPoints(): Vector3[]

  get worldPoints(): Vector3[]

  get scale(): number

  get isLeft(): boolean
}

export function createVirtualHand(
  tracking: ITrackingGraph<Results>,
  camera: OrthographicCamera,
  videoFlipped: IObservableProperty<boolean>,
) {
  let isVisible = false

  tracking.resultsEvent.subscribe(onResults)

  const visibilityChangedEvent = produceObserver<boolean>()
  const updatedEvent = produceObserver<IHandData>()

  function onResults(results: Results) {
    const normalizedLandmarks = results?.multiHandLandmarks[0]
    const worldLandmarks = results?.multiHandWorldLandmarks[0]

    if (!normalizedLandmarks || normalizedLandmarks.length === 0) {
      if (isVisible) {
        isVisible = false
        visibilityChangedEvent.notify(false)
      }
      return
    } else {
      if (!isVisible) {
        isVisible = true
        visibilityChangedEvent.notify(true)
      }
    }

    const transformedPoints = normalizedLandmarks.map(landmark => {
      if (videoFlipped.value) {
        return transformPointToWorldPoint(camera, 1 - landmark.x, landmark.y, landmark.z)
      }
      return transformLandmarkToWorldPoint(camera, landmark)
    })
    const worldPoints = worldLandmarks.map((point) => new Vector3((videoFlipped.value ? -1 : 1) * point.x, -point.y, -point.z))
    const scale = calculateScale(transformedPoints)
    const isLeft = (results.multiHandedness[0].label === 'Left') ? !videoFlipped.value : videoFlipped.value

    updatedEvent.notify({ rawData: results, transformedPoints, worldPoints, scale, isLeft })
  }

  function calculateScale(points: Vector3[]): number {
    const v1_1 = points[17]
    const v2_1 = points[5]
    const v1_2 = points[0]
    const v2_2 = points[5]

    return Math.max(v1_1.distanceTo(v2_1) * 0.25 * 0.8, v1_2.distanceTo(v2_2) * 0.2 * 0.8)
  }

  return { updatedEvent, visibilityChangedEvent }
}
