import styles from '../styles.module.css'
import { isMobileOrTablet } from '../utils/device-utils'
import { CameraDirection } from '../camera'

interface Props {
  cameraIsLoading: boolean
  cameraDirection: CameraDirection
  setCameraDirection: (cameraDirection: CameraDirection) => void
}

export function SwitchCameraButton(props: Props){
  function toggleCamera() {
    function chooseDirection() {
      if (!isMobileOrTablet()) return CameraDirection.User

      return props.cameraDirection === CameraDirection.User ? CameraDirection.Environment : CameraDirection.User
    }

    if (props.cameraIsLoading) return

    props.setCameraDirection(chooseDirection())
  }

  return <div className={styles.switch__camera_wrap}>
      <button className={styles.switch_camera_button} onClick={toggleCamera}>
        {!props.cameraIsLoading && <img src={`${process.env.PUBLIC_URL}/assets/arrows_around.svg`} alt='switch camera'/>}
      </button>
    </div>
}
