import { Color } from "three"


export function getPixel(sourceTexture: Uint8Array, sourceWidth: number, x: number, y: number) {
    const index = (x + y * sourceWidth) * 4
    if (index < 0 || index >= sourceTexture.length) {
        return new Color(1, 0, 1)
    }
    return new Color(sourceTexture[index] / 255, sourceTexture[index + 1] / 255, sourceTexture[index + 2] / 255)
}

export function zeros(length: number): number[] {
    return Array.from({ length }, () => 0)
}