//@ts-ignore
import styles from '../styles.module.css'
import { Player } from '@lottiefiles/react-lottie-player';

export function LoaderComponent({progress} : {progress: number}){  
  return <div className={styles.loading__screen_wrap}>
      <Player
        //style={{ width: '1024px', height: '720' }}        
        autoplay
        loop
        background="#222D33"
        className={styles.loading__screen}
        id="loading__lottie-wrap"
        src={`${process.env.PUBLIC_URL}/assets/loading-animation.json`}
      />
      <div className={styles.progressPercents}>{(progress * 100).toFixed()}%</div>
    </div>
}

export default LoaderComponent
