import { NormalizedLandmark } from '@mediapipe/hands'
import { OrthographicCamera, Vector3 } from 'three'

export function transformLandmarkToWorldPoint(
  camera: OrthographicCamera,
  landmark: NormalizedLandmark,
): Vector3 {
  return transformPointToWorldPoint(camera, landmark.x, landmark.y, landmark.z)
}

export function transformPointToWorldPoint(
  camera: OrthographicCamera,
  x: number,
  y: number,
  z: number,
): Vector3 {
  return new Vector3
  (
    (x * 2 - 1) * camera.right,
    -(y * 2 - 1) * camera.top,
    -z * 2 * camera.right,
  )
}
