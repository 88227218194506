import { LoadingManager, PMREMGenerator, Texture, WebGLRenderer } from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

interface IEnvironment { 
  map: Texture; 
  intensity: number 
}

async function initializeEnvironment(
  renderer: WebGLRenderer, 
  progressCallback: (progress: number) => void)
: Promise<IEnvironment> {
  const loadingManager = new LoadingManager(undefined, ((url, loaded, total) => {
    progressCallback(loaded / total)
  }))
  
  const environmentTexture = await new RGBELoader(loadingManager).loadAsync(
    `${process.env.PUBLIC_URL}/assets/environment2.hdr`
    )
  const pmremGenerator = new PMREMGenerator(renderer)
  pmremGenerator.compileCubemapShader()
  const generatedCubeRenderTarget = pmremGenerator.fromEquirectangular(environmentTexture)

  return {
    map: generatedCubeRenderTarget.texture,
    intensity: 0.75,
  }
}

export async function getEnvironment(
  renderer: WebGLRenderer, 
  progressCallback: (progress: number) => void
): Promise<{ map: Texture; intensity: number }> {
  return await initializeEnvironment(renderer, progressCallback)  
}
