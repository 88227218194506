import styles from '../styles.module.css'
import { PerformanceMeter, PerformanceMeterProps } from './performance-meter'

export interface PerformanceGroupProps {
  blocks: PerformanceMeterProps[]
}

export function PerformanceGroup(props: PerformanceGroupProps){  
  //TODO Fix that. Is this proper way to create react components?!
  return <div className={styles.PerformanceGroup}>
      {props.blocks.map(block => PerformanceMeter(block))}
    </div>  
}
