import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils'
import * as FaceMesh from '@mediapipe/face_mesh'
import * as Hands from '@mediapipe/hands'
import * as Pose from '@mediapipe/pose'
import '@mediapipe/hands'
import '@mediapipe/face_mesh'
import '@mediapipe/pose'
import { currentCameraZoom } from '../zoom'
import { Face } from 'three'

export function drawImage(
  canvas: HTMLCanvasElement | undefined,
  results: any,
  flipX: boolean,
) {
  const ctx = canvas?.getContext('2d')
  if (!ctx || !canvas) {
    return
  }

  ctx?.clearRect(0, 0, canvas.width || 0, canvas.height || 0)

  const scale = currentCameraZoom.value

  const sw = canvas.width * scale
  const sh = canvas.height * scale
  const sx = (canvas.width - sw) / 2
  const sy = (canvas.height - sh) / 2

  ctx.resetTransform()
  if (flipX) {
    ctx.translate(canvas.width, 0)
    ctx.scale(-1, 1)
  }

  ctx.drawImage(results.image, sx, sy, sw, sh, 0, 0, canvas.width, canvas.height)
  //ctx?.drawImage(results.image, 0, 0, canvas.width, canvas.height)
}

export function drawHandsResultsOnCanvas(
  canvas: HTMLCanvasElement | undefined,
  results: Hands.Results,
) {
  const ctx = canvas?.getContext('2d')
  if (!ctx || !canvas) {
    return
  }
  for (const landmarks of results.multiHandLandmarks) {
    drawConnectors(ctx, landmarks, Hands.HAND_CONNECTIONS, {
      color: '#00FF00',
      lineWidth: 5,
    })
    drawLandmarks(ctx, landmarks, {
      color: '#FF0000',
      lineWidth: 2,
    })
  }
}

export function drawFaceMeshResultsOnCanvas(
  canvas: HTMLCanvasElement | undefined,
  results: FaceMesh.Results,
) {
  // Draw the overlays.
  const ctx = canvas?.getContext('2d')
  if (!ctx || !canvas) {
    return
  }

  if (results.multiFaceLandmarks) {
    for (const landmarks of results.multiFaceLandmarks) {

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_TESSELATION,
        { color: '#C0C0C070', lineWidth: 1 })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_RIGHT_EYE,
        { color: '#FF3030' })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_RIGHT_EYEBROW,
        { color: '#FF3030' })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_LEFT_EYE,
        { color: '#30FF30' })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_LEFT_EYEBROW,
        { color: '#30FF30' })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_FACE_OVAL,
        { color: '#E0E0E0' })

      drawConnectors(
        ctx, landmarks, FaceMesh.FACEMESH_LIPS, { color: '#E0E0E0' })
      // if (solutionOptions.refineLandmarks) {
      //
      //
      //   drawConnectors(
      //     ctx, landmarks, facemeshrightiris,
      //     {color: '#FF3030'});
      //   drawConnectors(
      //     ctx, landmarks, facemeshleftiris,
      //     {color: '#30FF30'});
      // }
    }
  }
  ctx.restore()
}

export function drawPoseResultsOnCanvas(
  canvas: HTMLCanvasElement | undefined,
  results: Pose.Results) {

  const ctx = canvas?.getContext('2d')
  if (!ctx || !canvas) {
    return
  }

  if (results.segmentationMask) {
    ctx.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height)
  }

  drawConnectors(ctx, results.poseLandmarks, Pose.POSE_CONNECTIONS,
    { color: '#00FF00', lineWidth: 4 })
  drawLandmarks(ctx, results.poseLandmarks,
    { color: '#FF0000', lineWidth: 2 })
  
  ctx.restore()
}
