import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import {
  CylinderBufferGeometry,
  LoadingManager,
  MathUtils,
  Mesh,
  Object3D,
  sRGBEncoding,
  Vector3,
} from 'three'
import { createOccluderMaterial } from './utils/materials'

async function loadModel(path: string, onProgress?: (event: ProgressEvent) => void): Promise<GLTF> {
  const loadingManager = new LoadingManager(undefined, (url, loaded, total) => {
    onProgress?.(new ProgressEvent('progress', { loaded: loaded, total: total }))
  })

  const loader = new GLTFLoader(loadingManager)
  return await loader.loadAsync(path)
}

export async function importEarring(path: string, onProgress?: (event: ProgressEvent) => void): Promise<(Object3D)> {
  const config = {
    jewelryScale: 1,
    jewelryRotation: new Vector3(0, -90, 0),
    occluderScale: 0.175,
    occluderRotation: new Vector3(90, 0, 0),
  }

  const gltf = await loadModel(path, onProgress)

  patchScaleAndRotation(gltf.scene, config.jewelryScale, config.jewelryRotation)

  const rootObject = new Object3D()

  applyOccluderMaterials(gltf)

  rootObject.add(gltf.scene)

  updateTextureEncoding(rootObject)

  return rootObject
}

export async function importJewelry(path: string, generateOccluder: boolean, onProgress?: (event: ProgressEvent) => void): Promise<(Object3D)> {
  const config = {
    jewelryScale: 1.2,
    jewelryRotation: new Vector3(90, 0, -90),
    occluderScale: 0.75,
    occluderRotation: new Vector3(90, 0, 0),
  }

  const gltf = await loadModel(path, onProgress)

  patchScaleAndRotation(gltf.scene, config.jewelryScale, config.jewelryRotation)

  const rootObject = new Object3D()

  const occluderWasAdded = applyOccluderMaterials(gltf)

  if (generateOccluder && !occluderWasAdded) {
    rootObject.add(createOccluder(config.occluderScale, config.occluderRotation))
  }
  rootObject.add(gltf.scene)

  updateTextureEncoding(rootObject)

  return rootObject
}

function applyOccluderMaterials(gltf: GLTF) {
  let occluderWasAdded = false;
  gltf.scene.traverse(obj => {
    if (obj.name == '#Occluder') {
      // (obj as Mesh).material = createOccluderMaterial()
      transformMeshToOccluder(obj as Mesh); //STOLEN FROM MAIN SITE'S COMPILED SHIT
      occluderWasAdded = true;
    }
  })
  return occluderWasAdded;
}

function patchScaleAndRotation(obj: Object3D, scale: number, rotation: Vector3) {
  obj.scale.setScalar(scale)
  obj.rotation.setFromVector3(vectorDegToVectorRad(rotation))
}

function createOccluder(scale: number, rotation: Vector3) {
  const geometry = new CylinderBufferGeometry(1, 1, 8, 32)
  // const material = createOccluderMaterial() 
  const mesh = new Mesh(geometry)//STOLEN FROM MAIN SITE'S COMPILED SHIT
  mesh.scale.setScalar(scale)
  mesh.rotation.setFromVector3(vectorDegToVectorRad(rotation))
  transformMeshToOccluder(mesh);
  return mesh;

  return mesh
}

function transformMeshToOccluder(i: Mesh) { //STOLEN FROM MAIN SITE'S COMPILED SHIT
  const e = createOccluderMaterial();
  i.material = e;
  i.renderOrder = -1;
}

function vectorDegToVectorRad(vector: Vector3): Vector3 {
  return new Vector3(
    MathUtils.degToRad(vector.x),
    MathUtils.degToRad(vector.y),
    MathUtils.degToRad(vector.z),
  )
}

function updateTextureEncoding(object: Object3D) {
  const encoding = sRGBEncoding
  traverseMaterials(object, (material) => {
    if (material.map) material.map.encoding = encoding
    if (material.emissiveMap) material.emissiveMap.encoding = encoding
    if (material.map || material.emissiveMap) material.needsUpdate = true
  })
}

function traverseMaterials(object: Object3D, callback: (value: any, index: number, array: any[]) => void) {
  object.traverse((node) => {
    const mesh = node as Mesh
    if (mesh && mesh.material) {
      const materials = Array.isArray(mesh.material) ? mesh.material : [mesh.material]
      materials.forEach(callback)
    }
  })
}

